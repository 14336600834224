import { ParagraphProductListFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import Wrapper from '../Wrapper';
import React from 'react';
import { useStore } from '../../../store/provider';
import { ProductListGridSlider } from '../../Common/ProductListGridSlider';

interface ProductListProps {
  data: ParagraphProductListFragmentFragment;
}

const ProductList: React.FC<ProductListProps> = ({ data }) => {
  const store = useStore();

  return (
    <Wrapper className="ParagraphProductList" width="100%">
      <Container>
        <ProductListGridSlider
          title={data.title}
          alwaysSlider={data.desktopAsSlider}
          items={data.data?.items}
          productTeaserProps={{
            hideText: true,
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default ProductList;
