import { FC, useRef, useState } from 'react';
import { ProductTeaserFragmentFragment } from '../../generated/types';
import TeaserGrid from './TeaserGrid';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ProductTeaser, {
  ProductTeaserProps,
} from '../Node/Product/ProductTeaser';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface ProductListGridSliderProps {
  items?: ProductTeaserFragmentFragment[];
  productTeaserProps?: Partial<ProductTeaserProps>;
  alwaysSlider?: boolean;
  title?: string;
}

export const ProductListGridSlider: FC<ProductListGridSliderProps> = ({
  items,
  productTeaserProps,
  alwaysSlider,
  title,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slickRef = useRef<Slider>(null);

  let slidesToShow =
    useBreakpointValue({
      base: 1.4,
      md: 3.4,
    }) || 1.4;

  const hasNext = currentSlide + slidesToShow < items.length;
  const hasPrevious = currentSlide > 0;

  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      <Flex mb={4} alignItems="center" justifyContent="space-between">
        {title && <Heading fontSize="2xl">{title}</Heading>}
        <Box
          display={{
            base: 'none',
            md: alwaysSlider ? 'block' : 'none',
          }}
        >
          <Button
            pl={3}
            pr={3}
            size="sm"
            disabled={!hasPrevious}
            onClick={() => slickRef?.current?.slickPrev()}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            ml={2}
            pl={3}
            pr={3}
            size="sm"
            disabled={!hasNext}
            onClick={() => slickRef?.current?.slickNext()}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      </Flex>

      <Box
        display={{
          base: 'block',
          md: alwaysSlider ? 'block' : 'none',
        }}
        overflow="hidden"
        width="100%"
      >
        <Slider
          slidesToShow={slidesToShow}
          beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
          infinite={false}
          ref={slickRef}
          arrows={false}
        >
          {items.map((teaser) => (
            <Box key={teaser!.id} pr={4}>
              <ProductTeaser data={teaser!} {...productTeaserProps} />
            </Box>
          ))}
        </Slider>
        <HStack spacing={1} justifyContent="center" mt={4}>
          {items.map((item, i) => (
            <Box
              key={i}
              width="10px"
              height="10px"
              bg={currentSlide === i ? 'gray.500' : 'gray.200'}
              borderRadius="full"
              cursor="pointer"
              onClick={() => slickRef?.current?.slickGoTo(i)}
              _hover={{
                bg: 'gray.500',
              }}
            />
          ))}
        </HStack>
      </Box>
      {!alwaysSlider && (
        <Box
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <TeaserGrid columns={3} spacing={8}>
            {items.map((teaser) => (
              <ProductTeaser
                key={teaser!.id}
                data={teaser!}
                {...productTeaserProps}
              />
            ))}
          </TeaserGrid>
        </Box>
      )}
    </>
  );
};
