import {
  ProductFragmentFragment,
  ParagraphColumns,
  ProductTeaserFragmentFragment,
} from '../../../generated/types';
import React from 'react';
import { TeaserProps } from '../../../types/global-types';
import { Box } from '@chakra-ui/react';
import { useStore } from '../../../store/provider';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import EbikeAtlasTeaser from '../../Common/EbikeAtlasTeaser';

export type ProductTeaserProps = TeaserProps & {
  data: ProductTeaserFragmentFragment;
};

const ProductTeaser: React.FC<ProductTeaserProps> = ({
  data,
  showCategory,
  hideText,
  ...rest
}) => {
  const store = useStore();

  return (
    <Box key={data.id} width="100%">
      <EbikeAtlasTeaser
        type="product"
        text={hideText ? null : data.body}
        image={data.teaserImage?.productImage?.url}
        imageAlt={data.teaserImage?.imageAlt}
        imageTitle={data.teaserImage?.imageTitle}
        title={data.title}
        url={data.url}
        imageWidth={data.teaserImage?.productImage?.width || 300}
        imageHeight={data.teaserImage?.productImage?.height || 300}
        vertical
        customMeta={
          data.price ? (
            <Box fontSize="lg">€ {data.price} inkl. MwSt.</Box>
          ) : null
        }
        ctaText="Shop now"
        withMobileVersion
        {...rest}
      />
    </Box>
  );
};

export default ProductTeaser;
